/* src/App.css */

/* Reset some default styles for consistency */
body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

/* Fullscreen Container */
.fullscreen {
  height: 100vh; /* Full height of the viewport */
  width: 100vw;  /* Full width of the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f2f5; /* Light background for contrast */
}

/* App Header with Shadow and Rounded Corners */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  width: 90%; /* Default width */
  max-width: 600px; /* Optional: Limit the maximum width */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  /* Added Shadow and Rounded Corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 40px;
  position: relative; /* To contain the spinner overlay */
}

/* Form Styles */
form {
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

input[type="text"]:disabled,
input[type="email"]:disabled {
  background-color: #e0e0e0;
}

/* Submit Button Styles */
.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #61dafb;
  border: none;
  border-radius: 4px;
  color: #282c34;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover:not(:disabled) {
  background-color: #21a1f1;
}

.submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Success Message Styles */
.success-message {
  text-align: center;
}

.success-message h2 {
  margin-bottom: 10px;
}

.success-message p {
  font-size: 1.1em;
}

/* Spinner Overlay */
.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px; /* Match the App-header's border radius */
  z-index: 1000; /* Ensure it's on top */
}

/* Spinner Styles */
.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

/* Spin Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive Adjustment for Small Screens (e.g., iPhone 15) */
@media (max-width: 428px) {
  .fullscreen {
    width: 100vw; /* Ensure it uses full width */
    padding: 10px; /* Optional: Add some padding */
  }

  .App-header {
    width: 80vw; /* 80% of the viewport width */
    min-height: auto; /* Allow height to adjust based on content */
    padding: 20px; /* Reduce padding for smaller screens */
  }
}

/* Prevent Background Scrolling when Spinner is Active */
body.spinner-active {
  overflow: hidden;
}
